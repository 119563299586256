import React from "react";
import "./style.css";

export default function App() {
  return (

    <main className="main-06">

      <div className="header header-06">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xs-12 col-md-4">
              <a href="#" className="logo"> <img src="./assets/img/logo.png" alt="" /></a>
            </div>
            <div className="col-xs-12 col-md-8">
              <div className="header-right text-right">
                <p> GSTIN : 23AAMCR7865B1ZS</p>
                <p>CIN: U43219MP2023PTC06641</p>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="main-wrapper demo-06">

        <div className="hero-area">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">

                <div className="heading">
                  <h1 className="text-white wow fadeInUp" data-wow-delay=".2s" style={{ visibility: "visible", animationDelay: "0.2s", animationName: "fadeInUp" }}>
                    RASS HEAVY ELECTRICALS PVT. LTD.
                  </h1>
                  {/* <h1 className="text-white wow fadeInUp" data-wow-delay=".2s" style={{ visibility: "visible", animationDelay: "0.2s", animationName: "fadeInUp" }}>We Are Coming Soon</h1> */}
                </div>

              </div>
              {/* <div className="col-xl-7 col-lg-7">

                <div className="wow fadeInRight" data-wow-delay=".4s" data-countdown="2021/10/01" style={{ visibility: "visible", animationDelay: "0.4s", animationName: "fadeInRight" }}><div className="countdown d-flex"><div className="single-count-content"><span className="count">00</span><p className="text">Days</p></div><div className="single-count-content"><span className="count">00</span><p className="text">Hours</p></div><div className="single-count-content"><span className="count">00</span><p className="text">Minutes</p></div><div className="single-count-content"><span className="count">00</span><p className="text">Seconds</p></div></div></div>

              </div>
              <div className="col-xl-5 col-lg-5">

                <p className="wow fadeInLeft" data-wow-delay=".4s" style={{ visibility: "visible", animationDelay: "0.4s", animationName: "fadeInLeft" }}>We're strong believers that the best solutions come from gathering new insights and pushing conventional boundaries.</p>

              </div> */}
            </div>
          </div>
        </div>


        <div className="team-area">
          <div className="container">

            <div className="row">
              <div className="col-xl-12">

                <div className="section-title">
                  <h1 className="wow fadeInUp" data-wow-delay=".2s" style={{ visibility: "visible", animationDelay: "0.2s", animationName: "fadeInUp" }}>Who we are</h1>
                  <p className="wow fadeInLeft" data-wow-delay=".4s" style={{ visibility: "visible", animationDelay: "0.4s", animationName: "fadeInLeft" }}>
                    Our company is a leading manufacturer and trader specialising in heavy electrical equipment. We want to establish ourselves as a trusted name in the industry, known for our commitment to quality; innovation, and customer satisfaction. From Cables and Fuses to approximately every product available in 11 Kv to 33Kv transmission-line, we offer a comprehensive range of products that cater to the diverse needs of our clients across various sectors.
                  </p>

                  <video width="100%" height="400" disablePictureInPicture="" tabIndex="-1" aria-hidden="true" className="video_inview ban_video tv_video" playsInline="" autoPlay muted="" loop="" controls controlsList="nodownload">
                    <source data-src="our_mission.mp4" type="video/mp4" src="assets/video/our_mission.mp4" />
                  </video>

                  {/* <h2 className="wow fadeInUp" data-wow-delay=".4s" style={{ visibility: "visible", animationDelay: "0.4s", animationName: "fadeInUp" }}>
                  Every month, amounts of Projects <br className="d-none d-lg-block" /> handover by this genius team. 
                  </h2> */}
                  {/* <h2 className="wow fadeInUp" data-wow-delay=".4s" style={{ visibility: "visible", animationDelay: "0.4s", animationName: "fadeInUp" }}>
                    Our company is a leading manufacturer and trader specialising in heavy electrical equipment. We want to establish ourselves as a trusted name in the industry, known for our commitment to quality; innovation, and customer satisfaction. From Cables and Fuses to approximately every product available in 11 Kv to 33Kv transmission-line, we offer a comprehensive range of products that cater to the diverse needs of our clients across various sectors.
                  </h2> */}
                </div>

              </div>
            </div>

            <div className="row">
              <div className="col-xl-9">

                <div className="section-title">
                  <h1 className="wow fadeInUp" data-wow-delay=".2s" style={{ visibility: "visible", animationDelay: "0.2s", animationName: "fadeInUp" }}>Our Real Hero</h1>
                  {/* <h2 className="wow fadeInUp" data-wow-delay=".4s" style={{ visibility: "visible", animationDelay: "0.4s", animationName: "fadeInUp" }}>Every month, amounts of Projects <br className="d-none d-lg-block" /> handover by this genius team.</h2> */}
                  <h2 className="wow fadeInUp" data-wow-delay=".4s" style={{ visibility: "visible", animationDelay: "0.4s", animationName: "fadeInUp" }}>
                    Board Of Members
                  </h2>
                </div>

              </div>
            </div>

            <div className="team-wrapper">

              <div className="team-item wow fadeInUp" data-wow-delay=".2s" style={{ visibility: "visible", animationDelay: "0.2s", animationName: "fadeInUp" }}>
                <div className="team-img">
                  <img src="assets/img/img-1.jpg" alt="" />
                  {/* <ul className="social-links">
                    <li><a href="#"><i className="lni lni-facebook-filled"></i></a></li>
                    <li><a href="#"><i className="lni lni-twitter-filled"></i></a></li>
                    <li><a href="#"><i className="lni lni-instagram-original"></i></a></li>
                    <li><a href="#"><i className="lni lni-linkedin"></i></a></li>
                  </ul> */}
                </div>
                <div className="team-info">
                  <h4>Rajesh Seetlani</h4>
                  <p>Director</p>
                </div>
              </div>


              <div className="team-item wow fadeInUp" data-wow-delay=".4s" style={{ visibility: "visible", animationDelay: "0.4s", animationName: "fadeInUp" }}>
                <div className="team-img">
                  <img src="assets/img/img-2.jpg" alt="" />
                  {/* <ul className="social-links">
                    <li><a href="#"><i className="lni lni-facebook-filled"></i></a></li>
                    <li><a href="#"><i className="lni lni-twitter-filled"></i></a></li>
                    <li><a href="#"><i className="lni lni-instagram-original"></i></a></li>
                    <li><a href="#"><i className="lni lni-linkedin"></i></a></li>
                  </ul> */}
                </div>
                <div className="team-info">
                  <h4>Sakshi Seetlani</h4>
                  <p>Director</p>
                </div>
              </div>


              <div className="team-item wow fadeInUp" data-wow-delay=".6s" style={{ visibility: "visible", animationDelay: "0.6s", animationName: "fadeInUp" }}>
                <div className="team-img">
                  <img src="assets/img/img-3.jpg" alt="" />
                  {/* <ul className="social-links">
                    <li><a href="#"><i className="lni lni-facebook-filled"></i></a></li>
                    <li><a href="#"><i className="lni lni-twitter-filled"></i></a></li>
                    <li><a href="#"><i className="lni lni-instagram-original"></i></a></li>
                    <li><a href="#"><i className="lni lni-linkedin"></i></a></li>
                  </ul> */}
                </div>
                <div className="team-info">
                  <h4>Swayam Seetlani</h4>
                  <p>CEO</p>
                </div>
              </div>

            </div>

            <div className="row">
              <div className="col-xl-12">

                <div className="section-title">
                  <h1 className="wow fadeInUp" data-wow-delay=".2s" style={{ visibility: "visible", animationDelay: "0.2s", animationName: "fadeInUp" }}>Mission</h1>
                  <p className="wow fadeInLeft" data-wow-delay=".4s" style={{ visibility: "visible", animationDelay: "0.4s", animationName: "fadeInLeft" }}>
                    Our mission is to provide reliable, efficient, and sustainable electrical solutions that empower industries and communities to thrive. We are dedicated to pushing the boundaries of technology, enhancing operational excellence, and fostering long-term partnerships with our customers
                  </p>
                </div>

              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">

                <div className="section-title">
                  <h1 className="wow fadeInUp" data-wow-delay=".2s" style={{ visibility: "visible", animationDelay: "0.2s", animationName: "fadeInUp" }}>Core Values</h1>
                  <p className="wow fadeInLeft" data-wow-delay=".4s" style={{ visibility: "visible", animationDelay: "0.4s", animationName: "fadeInLeft" }}>
                    <b><u>Quality:</u> </b>We uphold the higheststandards of quality in everything we do, ensuring that our products meet or exceed industry benchmarks.
                    <br />
                    <b><u>Innovation:</u> </b>We continuously innovate and adapt to emerging trends and technologies, staying ahead of the curve to deliver cutting-edge solutions.
                    <br />
                    <b><u>Integrity:</u> </b>We conduct our business with honesty, transparency, and integrity, earning the trust and respect of our stakeholders.
                    <br />
                    <b><u>Customer Focus:</u> </b>We prioritize the needs of our customers, striving to exceed their expectations through personalized service and tailored solutions.
                    <br />
                    <b><u>Sustainability:</u> </b>We are committed to minimizing our environmental footprint and promoting sustainability throughout our operations and supply chain.
                  </p>
                </div>

              </div>
            </div>

          </div>
        </div>

      </div>


      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="copyright wow fadeInLeft" data-wow-delay=".2s" style={{ visibility: "visible", animationDelay: "0.2s", animationName: "fadeInLeft" }}>
                <p>Contact Address: D-9 Sector-H, Road Number T-3, GovindPura Industrial Area, Bhopal 462023 (M.P.)</p>
                <p>Email: <a href="mailto:rassheavyelectricalspvtltd@gmail.com" rel="nofollow">rassheavyelectricalspvtltd@gmail.com</a></p>
                <p>Mobile: <a href="tel:+91-9302372100" rel="nofollow">+91-9302372100</a></p>
                {/* <a href="htts://uideck.com" rel="nofollow">UIdeck</a> */}
              </div>
            </div>

            {/* <div className="col-md-5">
              <div className="credit wow fadeInRight" data-wow-delay=".4s" style={{ visibility: "visible", animationDelay: '0.4s', animationName: "fadeInRight" }}>
                <p>Designed and Developed by <a rel="nofollow" href="#">Karan Kkhilwani</a></p>
              </div>
            </div> */}
          </div>

          <br />  <br />
          <div className="row">
            <div className="col-md-12">
              <div className="text-center fadeInCenter" data-wow-delay=".4s" style={{ visibility: "visible", animationDelay: '0.4s', animationName: "fadeInRight" }}>
                <p>Copyright 2024 RASS.</p>
                {/* © */}
                <p>All Rights Reserved.</p>
              </div>
            </div>

          </div>
        </div>
      </div>

    </main>

  );
}
